/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
import { createPortal } from 'react-dom';

import ClickOutside from 'Component/ClickOutside';
import CloseIcon from 'Component/CloseIcon';
import {
    Popup as SourcePopup
} from 'SourceComponent/Popup/Popup.component';

import './Popup.override.style';

/** @namespace Tigerone/Component/Popup/Component */
export class PopupComponent extends SourcePopup {
    // TODO implement logic

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    __construct() {
        super.__construct();
        this.state = {
            isPopupOpen: false
        };
        this.renderAnimatedClose = this.renderAnimatedClose.bind(this);
    }

    renderContent() {
        const {
            children, contentMix
        } = this.props;
        const isVisible = this.getIsVisible();
        if (!isVisible) {
            return null;
        }

        return (
            <ClickOutside onClick={ this.renderAnimatedClose }>
            <div block="Popup" elem="Content" mix={ contentMix }>
                <header block="Popup" elem="Header">
                    { this.renderTitle() }
                    { this.renderCloseButton() }
                </header>
                { this.renderNotifications() }
                { children }
            </div>
            </ClickOutside>
        );
    }

    renderCloseButton() {
        return (
            <button
              block="Popup"
              elem="CloseBtn"
              aria-label="Close"
              onClick={ this.renderAnimatedClose }
            >
                <CloseIcon />
            </button>
        );
    }

    renderAnimatedClose() {
        this.setState({ isPopupOpen: true });

        setTimeout(() => {
            this.hidePopUp();
            this.setState({ isPopupOpen: false });
        // eslint-disable-next-line no-magic-numbers
        }, 250);
    }

    render() {
        const { mix, areOtherOverlaysOpen, popupClass } = this.props;
        const { isPopupOpen } = this.state;
        const isVisible = this.getIsVisible();
        const willClose = isPopupOpen ? 'willClose' : '';

        return createPortal(
            <div
              id={ popupClass || null }
              ref={ this.overlayRef }
              className={ willClose }
              block="Popup"
              mods={ { isVisible, isInstant: areOtherOverlaysOpen } }
              mix={ { ...mix, mods: { ...mix.mods, isVisible } } }
            >
                { this.renderContent() }
            </div>,
            document.body
        );
    }
}

export default PopupComponent;
