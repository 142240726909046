/* eslint-disable max-len */
import { PureComponent } from 'react';

/** @namespace Tigerone/Component/GoogleSearchIcon/Component */
export class GoogleSearchIconComponent extends PureComponent {
    render() {
        // eslint-disable-next-line react/prop-types, no-magic-numbers
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
            <path d="M16.1 17.5125L10.5 11.9125C9.99995 12.3125 9.42495 12.6292 8.77495 12.8625C8.12495 13.0959 7.43328 13.2125 6.69995 13.2125C4.88328 13.2125 3.34595 12.5835 2.08795 11.3255C0.829285 10.0669 0.199951 8.52919 0.199951 6.71252C0.199951 4.89586 0.829285 3.35819 2.08795 2.09952C3.34595 0.841524 4.88328 0.212524 6.69995 0.212524C8.51662 0.212524 10.0543 0.841524 11.313 2.09952C12.571 3.35819 13.2 4.89586 13.2 6.71252C13.2 7.44586 13.0833 8.13752 12.85 8.78752C12.6166 9.43752 12.3 10.0125 11.9 10.5125L17.525 16.1375C17.7083 16.3209 17.8 16.5459 17.8 16.8125C17.8 17.0792 17.7 17.3125 17.5 17.5125C17.3166 17.6959 17.0833 17.7875 16.8 17.7875C16.5166 17.7875 16.2833 17.6959 16.1 17.5125ZM6.69995 11.2125C7.94995 11.2125 9.01262 10.7752 9.88795 9.90052C10.7626 9.02519 11.2 7.96252 11.2 6.71252C11.2 5.46252 10.7626 4.39986 9.88795 3.52452C9.01262 2.64986 7.94995 2.21252 6.69995 2.21252C5.44995 2.21252 4.38728 2.64986 3.51195 3.52452C2.63728 4.39986 2.19995 5.46252 2.19995 6.71252C2.19995 7.96252 2.63728 9.02519 3.51195 9.90052C4.38728 10.7752 5.44995 11.2125 6.69995 11.2125Z" fill="black" />
            </svg>
        );
    }
}
export default GoogleSearchIconComponent;
