/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable fp/no-let */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable max-len */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/* eslint-disable max-lines */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable spaced-comment */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { createRef } from 'react';

import FieldContainer from 'Component/Field';
import { FIELD_TYPE } from 'Component/Field/Field.config';
import Image from 'Component/Image';
import Link from 'Component/Link';
import Loader from 'Component/Loader';
import { ProductComponent } from 'Component/Product/Product.component';
import PRODUCT_TYPE from 'Component/Product/Product.config';
import TextPlaceholder from 'Component/TextPlaceholder';
import {
    GRID_LAYOUT,
    LIST_LAYOUT
} from 'Route/CategoryPage/CategoryPage.config';
import { ACCOUNT_LOGIN_URL, ACCOUNT_REGISTRATION_URL } from 'SourceRoute/MyAccount/MyAccount.config';
import { isSignedIn } from 'SourceUtil/Auth';
import { MixType } from 'Type/Common.type';
import { DeviceType } from 'Type/Device.type';
import { LayoutType } from 'Type/Layout.type';
import { LinkType } from 'Type/Router.type';
import { productFeaturesIcon, productShareIcon } from 'Util/Images';
import { formatCurrency } from 'Util/Price';
import { getProductFinalPrice } from 'Util/Product/Product';
import { VALIDATION_INPUT_TYPE_NUMBER } from 'Util/Validator/Config';

import ProductLabel from '../../@scandiweb/mageplaza-product-label/component/ProductLabel';

import './ProductCard.override.style';

/**
 * Product card
 * @class ProductCard
 * @namespace Tigerone/Component/ProductCard/Component */
export class ProductCardComponent extends ProductComponent {
    static propTypes = {
        ...ProductComponent.propTypes,
        linkTo: LinkType,
        device: DeviceType.isRequired,
        thumbnail: PropTypes.string,
        isLoading: PropTypes.bool,
        children: PropTypes.element,
        layout: LayoutType,
        mix: MixType,
        renderContent: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
        hideWishlistButton: PropTypes.bool,
        isWishlistEnabled: PropTypes.bool.isRequired,
        hideCompareButton: PropTypes.bool,
        parameters: PropTypes.objectOf(PropTypes.string).isRequired,
        showSelectOptionsNotification: PropTypes.func.isRequired,
        registerSharedElement: PropTypes.func.isRequired,
        closeSearchOverlay: PropTypes.func,
        shareIconClicked: PropTypes.func,
        notifyClick: PropTypes.func,
        categoryUrlSuffix: PropTypes.string
    };

    state ={
        showNotifyButton: false
    };

    static defaultProps = {
        ...ProductComponent.defaultProps,
        thumbnail: '',
        linkTo: {},
        children: null,
        isLoading: false,
        mix: {},
        renderContent: false,
        hideWishlistButton: false,
        hideCompareButton: false,
        layout: GRID_LAYOUT
    };

    contentObject = {
        renderCardLinkWrapper: this.renderCardLinkWrapper.bind(this),
        pictureBlock: {
            picture: this.renderPicture.bind(this)
        },
        content: {
            review: this.renderReviews.bind(this),
            productPrice: this.renderPrice.bind(this),
            mainDetails: this.renderMainDetails.bind(this),
            additionalProductDetails: this.renderBrand.bind(this)
        }
    };

    imageRef = createRef();

    className = 'ProductCard';

    registerSharedElement = this.registerSharedElement.bind(this);

    registerSharedElement() {
        const { registerSharedElement } = this.props;
        document.documentElement.scrollIntoView();
        registerSharedElement(this.imageRef);
    }

    //#region PRICE
    renderEmptyProductPrice() {
        return (
            <div
              block="ProductCard"
              elem="PriceWrapper"
              mods={ { isEmpty: true } }
            />
        );
    }

    renderPrice() {
        const {
            getActiveProduct,
            product: { type_id: baseType } = {},
            isSearchPageCard
        } = this.props;

        const { price_range: priceRange, type_id: typeId } = getActiveProduct();

        if (!priceRange) {
            return this.renderTextPlaceholder();
        }

        if (isSearchPageCard) {
            const {
                minimum_price: { final_price: { value, currency } = {} }
            } = priceRange;

            return (
                <div block="ProductCard" elem="PriceWrapper">
                    <div
                      className="ProductPrice"
                      block="ProductPrice"
                      elem="Price search-page"
                      mods={ { isPreview: true } }
                    >
                        { !value ? null : (
                            <span block="ProductPrice" elem="Price">
                                <span block="ProductPrice" elem="PriceBadge">
                                    { __('From') }
                                </span>
                                <span block="ProductPrice" elem="PriceValue">
                                     { formatCurrency(currency) }
                                    { ' ' }
                                    { value }
                                </span>
                            </span>
                        ) }
                    </div>
                </div>
            );
        }

        // If product is not a variant.
        const notConfigured = baseType !== PRODUCT_TYPE.configurable || typeId === baseType;

        return super.renderPrice(notConfigured);
    }

    renderPicture(mix = {}) {
        const {
            product: {
                id, name, image: { url } = {}, type_id
            },
            thumbnail,
            imageShow,
            activeProduct: { image: { path } = {} }
        } = this.props;

        this.sharedConfigComponent = (
            <Image
              imageRef={ this.imageRef }
              src={ !path ? url : thumbnail }
              alt={ name }
              ratio="custom"
              mix={ { block: 'ProductCard', elem: 'Picture', mix } }
              isPlaceholder={ !id }
            />
        );

        this.sharedComponent = (
            <Image
              imageRef={ this.imageRef }
              src={ thumbnail || url }
              alt={ name }
              ratio="custom"
              mix={ { block: 'ProductCard', elem: 'Picture', mix } }
              isPlaceholder={ !id }
            />
        );

        return (
            <>
                { (type_id === PRODUCT_TYPE.configurable)
                    ? this.sharedConfigComponent
                    : this.sharedComponent }
                <img
                  style={ imageShow ? null : { display: 'none' } }
                  alt={ name }
                  src={ thumbnail || url }
                />
            </>
        );
    }

    renderReviews() {
        const { layout } = this.props;

        return (
            <div block="ProductCard" elem="Reviews" mods={ { layout } }>
                { this.renderRatingSummary() }
            </div>
        );
    }

    renderProductCompareButton() {
        const { hideCompareButton } = this.props;

        if (hideCompareButton) {
            return null;
        }

        return this.renderCompareButton();
    }

    renderProductCardWishlistButton() {
        const { hideWishlistButton, isWishlistEnabled, product: { sku } = {} } = this.props;

        if (!sku) {
            return <TextPlaceholder />;
        }

        if (hideWishlistButton || !isWishlistEnabled) {
            return null;
        }

        return this.renderWishlistButton();
    }

    renderProductShareButton() {
        const { shareIconClicked, product: { url } = {} } = this.props;
        return (
            <button
              block="ProductButton"
              elem="ShareButton"
              type="button"
              onClick={ () => shareIconClicked(url) }
            >
                { productShareIcon() }
            </button>
        );
    }

    renderProductActions() {
        const { layout } = this.props;

        if (layout === 'grid') {
            return (
                <div block="ProductCard" elem="ProductActions">
                    { this.renderProductCardWishlistButton() }
                </div>
            );
        }

        return (
            <div block="ProductCard" elem="ProductActions">
                { isSignedIn() ? null : this.renderProductCardWishlistButton() }
            </div>
        );
    }

    renderMainDetails() {
        const { linkTo, product: { merchendise_img_allow } = {} } = this.props;
        return (
            <>
                    <div block="ProductCard" elem="ContentParent">
                <div block="ProductCard" elem="ContentGrid">
                    { isSignedIn() ? this.renderProductCardWishlistButton() : null }
                </div>
                <div block="ProductCard" elem="ContentName">
                { this.renderBrand() }
                <Link to={ linkTo }>{ this.renderName(false) }</Link>
                </div>
                <div block="ProductCard" elem="ContentImage">
                { merchendise_img_allow && isSignedIn() ? this.renderCardLinkWrapper(this.renderPicture()) : null }
                </div>
                    </div>
                <div block="ProductCard" elem="ProductListSku">
                { this.renderSkuName() }
                { this.renderStatus() }
                </div>
            </>
        );
    }

    renderCardLinkWrapper(children, mix = {}) {
        const {
            linkTo,
            product: { url, url_key },
            isSearchPageCard,
            closeSearchOverlay,
            productName,
            categoryUrlSuffix
        } = this.props;

        const shouldIncludeOnClick = isSearchPageCard;

        if (isSearchPageCard) {
            return (
                <Link
                  block="ProductCard"
                  elem="Link"
                  to={ categoryUrlSuffix
                      ? `${url_key}${categoryUrlSuffix}`
                      : url_key }
                  onClick={ closeSearchOverlay }
                  mix={ mix }
                  title={ productName }
                >
                    { children }
                </Link>
            );
        }

        if (!url) {
            return (
                <div block="ProductCard" elem="Link">
                    { children }
                </div>
            );
        }

        return (
            <Link
              block="ProductCard"
              elem="Link"
              to={ linkTo }
              onClick={ shouldIncludeOnClick ? this.registerSharedElement : null }
              mix={ mix }
              title={ productName }
            >
                { children }
            </Link>
        );
    }

    requiresConfiguration() {
        const {
            product: {
                type_id: type,
                options = [],
                links_purchased_separately
            }
        } = this.props;

        const configureBundleAndGrouped = type === PRODUCT_TYPE.bundle || type === PRODUCT_TYPE.grouped;
        const configureConfig = (type === PRODUCT_TYPE.configurable
                // eslint-disable-next-line max-len
                && Object.keys(super.getConfigurableAttributes()).length
                    !== Object.keys(this.getConfigurableAttributes()).length)
            // eslint-disable-next-line max-len
            || (type === PRODUCT_TYPE.configurable
                && Object.values(this.getConfigurableAttributes()).some(
                    (value) => value.attribute_values.length === 0
                ));
        const configureCustomize = options.some(
            ({ required = false }) => required
        );
        const configureDownloadableLinks = PRODUCT_TYPE.downloadable && links_purchased_separately === 1;

        return (
            configureBundleAndGrouped
            || configureConfig
            || configureCustomize
            || configureDownloadableLinks
        );
    }

    renderAddToCart() {
        const { layout } = this.props;

        // Deleted Default configuration method to add dropdown values

        return this.renderAddToCartButton(layout, true);
    }

    getConfigurableAttributes() {
        const filteredOptions = super.getConfigurableAttributes();

        return Object.fromEntries(
            Object.entries(filteredOptions).filter(([, option]) => {
                const { attribute_options: attributeOptions = {} } = option;

                return Object.values(attributeOptions).some(
                    ({ swatch_data: swatchData }) => swatchData
                );
            })
        );
    }

    renderQuantity() {
        return this.renderQuantityChanger();
    }

    renderLoginLink() {
        const {
            handleLogin,
            product: { sku }
        } = this.props;

        if (!sku) {
            return <TextPlaceholder />;
        }

        return (

            <Link
              block="ProductCard"
              elem="LoginLink"
              to={ ACCOUNT_LOGIN_URL }
              onClick={ handleLogin }
            >
                <button
                  block="ProductCard"
                  elem="LoginLinkButton"
                >
                { __('Login') }
                </button>
            </Link>
        );
    }

    renderRegisterLink() {
        const {
            product: { sku }
        } = this.props;

        if (!sku) {
            return <TextPlaceholder />;
        }

        return (
            <Link
              block="ProductCard"
              elem="RegisterLink"
              to={ ACCOUNT_REGISTRATION_URL }
            >
                { __('Register') }
            </Link>
        );
    }

    renderHoverLoginLink() {
        const {
            product: { sku },
            handleLogin
        } = this.props;

        if (!sku) {
            return <TextPlaceholder />;
        }

        return (
            <Link
              block="ProductCard"
              elem="HoverLoginLink"
              to={ ACCOUNT_LOGIN_URL }
              onClick={ handleLogin }
            >
                { __('Login to view price') }
            </Link>
        );
    }

    renderVisibleOnHover() {
        const {
            device, product: { sku, type_id } = {}, inStock, layout
        } = this.props;

        if (device.isMobile || !sku) {
            return null;
        }

        return (
            <div block="ProductCard" elem="FooterWrapper">
                { this.renderProductFeatures(layout) }
                { this.renderConfigurableOptions() }
                { isSignedIn() ? (
                    <div>
                    { type_id === 'bundle' ? (
                        <div block="ProductCard" elem="Content">
                            { this.renderViewProductOnHover() }
                        </div>
                    ) : !inStock ? (
                        <div block="ProductCard" elem="Footer">
                            { this.renderNotifyStockButton() }
                        </div>
                    ) : (
                        <div block="ProductCard" elem="Footer">
                            { this.renderQuantity() }
                            { this.renderAddToCart() }
                        </div>
                    ) }
                    </div>
                ) : (
                    this.renderHoverLoginLink()
                ) }
            </div>
        );
    }

    renderProductFeatures(layout) {
        const { product: { attributes } = {} } = this.props;

        const filteredArray = [];
        let FeaturesList = [];

        if (layout === 'grid') {
            FeaturesList = ['seeds_feminised', 'seeds_flowering_weeks'];
        } else {
            FeaturesList = [
                'seeds_feminised',
                'seeds_flowering_type',
                'seeds_flowering_weeks',
                'seeds_taste'
            ];
        }

        if (attributes) {
            FeaturesList.map((attribute) => filteredArray.push(attributes[attribute]));
        }

        const value = filteredArray.map((attribute) => {
            if (attribute) {
                const { attribute_type } = attribute;

                if (attribute_type === 'select') {
                    const { attribute_options, attribute_value } = attribute;

                    return (
                        <li className="featuresAttributeOptions">
                            <span>{ productFeaturesIcon() }</span>
                            { attribute_options[attribute_value]?.label }
                        </li>
                    );
                }

                if (attribute_type === 'text') {
                    const { attribute_value } = attribute;
                    if (!attribute_value) {
                        return null;
                    }

                    return (
                        <li>
                            <span>{ productFeaturesIcon() }</span>
                            { attribute_value }
                        </li>
                    );
                }
            }

            return true;
        });

        return (
            <ul block="ProductCard" elem="ProductFeaturesLists">
                { value }
            </ul>
        );
    }

    renderViewButton() {
        const {
            layout,
            product: { sku }
        } = this.props;

        if (!sku) {
            return <TextPlaceholder length="medium" />;
        }

        return (
            <button block="Button ViewProduct" mods={ { layout } }>
                <span>{ __('View Product') }</span>
            </button>
        );
    }

    renderNotifyStockButton() {
        const {
            layout, notifyClick, product, handlePopup
        } = this.props;
        const handleClickNotify = () => {
            notifyClick(product);
            handlePopup();
        };

        return (
            <div>
            <button
              block="Button NotifyStock"
              mods={ { layout } }
              onClick={ handleClickNotify }
            >
                <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 16.0001C1.45 16.0001 0.979333 15.8044 0.588 15.4131C0.196 15.0211 0 14.5501 0 14.0001V2.00006C0 1.45006 0.196 0.979394 0.588 0.588061C0.979333 0.196061 1.45 6.10352e-05 2 6.10352e-05H18C18.55 6.10352e-05 19.021 0.196061 19.413 0.588061C19.8043 0.979394 20 1.45006 20 2.00006V14.0001C20 14.5501 19.8043 15.0211 19.413 15.4131C19.021 15.8044 18.55 16.0001 18 16.0001H2ZM18 4.00006L10.525 8.67506C10.4417 8.72506 10.354 8.76239 10.262 8.78706C10.1707 8.81239 10.0833 8.82506 10 8.82506C9.91667 8.82506 9.82933 8.81239 9.738 8.78706C9.646 8.76239 9.55833 8.72506 9.475 8.67506L2 4.00006V14.0001H18V4.00006ZM10 7.00006L18 2.00006H2L10 7.00006ZM2 4.25006V2.77506V2.80006V2.78706V4.25006Z" fill="black" />
                </svg>
                <span>{ __('NOTIFY ME') }</span>
            </button>
            </div>
        );
    }

    calculateTotalPrice() {
        const { multiSelectedProducts, getActiveProduct } = this.props;

        const { price_range: priceRange } = getActiveProduct();
        const totalPrice = multiSelectedProducts.reduce((total, product) => total + (product?.price * product?.quantity), 0);

        const {
            minimum_price: { final_price: { currency } = {} } = {}
        } = priceRange;

        const roundedTotalPrice = totalPrice.toFixed(2);

        return (
            <span block="ProductCard" elem="TotalPriceValueList">
                { formatCurrency(currency) }
                { roundedTotalPrice }
            </span>
        );
    }

    renderQuantityChangerMultiSelect(item) {
        const {
            product: { variants = [], sku },
            setMultiSelect,
            maxQuantity,
            inStock
        } = this.props;

        const { attribute_code, value, brand_label } = item;

        const filteredVariants = variants.filter((variant) => variant.attributes[attribute_code].attribute_value === value);
        if (filteredVariants.length === 0) {
            return null;
        }

        const inStockStatus = inStock && filteredVariants.some((variant) => variant.stock_status === 'OUT_OF_STOCK');

        const variant = variants?.find((data) => data?.attributes[attribute_code]?.attribute_value === value);

        const inStockStatusLabel = variant?.salable_qty === 0;

        const index = variants?.findIndex((product) => {
            if (product?.attributes[attribute_code] && product?.attributes[attribute_code]?.attribute_value === value) {
                return true;
            }
        });

        if (!variants[index]) {
            return;
        }

        const price = getProductFinalPrice(variants[index]);
        const {
            price_range: {
                maximum_price: {
                    regular_price: {
                        value: regularPrice
                    },
                    discount: {
                        percent_off
                    }
                }
            }
        } = variants[index];

        return (
            <div block="Product" elem="ListProductValuesWrapper">
                <div block="Product" elem="ListProductlabel">
                <div block="Product" elem={ inStockStatusLabel ? 'DisableAvailableQtyReader' : 'SeedsNumber' }>{ brand_label }</div>
                { inStockStatusLabel ? <div block="Product" elem="OutOfStockList">{ __('Out of stock') }</div> : null }
                </div>
                { filteredVariants.map((variant) => {
                    const { price_range } = variant;
                    if (!price_range) {
                        return null;
                    }

                    const {
                        minimum_price: { final_price: { value: priceValue, currency } }
                    } = price_range;

                    return (
                        <div key={ `${brand_label}-${value}` } block="Product" elem="ListProductValues">
                            <div block="Product" elem={ inStockStatusLabel ? 'DisableAvailableQtyReader' : 'SeedsPrice' }>
                            <div>
                            { formatCurrency(currency) }
                            { price }
                            { percent_off
                                ? (
                                                <del aria-label="Old product price" block="ProductPrice" elem={ inStockStatusLabel ? 'DisableAvailableQtyReader' : 'HighPrices' }>
                                                     { formatCurrency(currency) }
                                                    { regularPrice }
                                                </del>
                                ) : null }
                            </div>
                            { percent_off ? <span block="ProductPrice" elem="discounts">{ `${percent_off}${__('% off')}` }</span> : null }
                            </div>
                            <div block="Product" elem={ inStockStatusLabel ? 'DisableAvailableQtyReader' : 'AvailableQtyWrapper' }>
                            <FieldContainer
                              type={ FIELD_TYPE.number }
                              attr={ {
                                  id: `item_qty_${brand_label}`,
                                  name: `item_qty_${brand_label}`,
                                  defaultValue: 0,
                                  max: maxQuantity,
                                  min: 0
                              } }
                              validationRule={ {
                                  inputType: VALIDATION_INPUT_TYPE_NUMBER.numeric,
                                  isRequired: true,
                                  range: {
                                      min: 0,
                                      max: maxQuantity
                                  }
                              } }
                              isDisabled={ inStockStatus }
                              vc
                              gt
                              mix={ { block: this.className, elem: 'Qty' } }
                              events={ { onChange: (e) => setMultiSelect(attribute_code, value, e, priceValue, sku) } }
                              validateOn={ ['onChange'] }
                            />
                            </div>
                        </div>
                    );
                }) }
            </div>
        );
    }

    renderSwatchMultiselect() {
        const {
            product: { configurable_options = {} } = {}
        } = this.props;

        return (
            <div
              block="ProductCard"
              elem="Multiselect"
            >
               { Object.values(configurable_options)?.map((option) => (
                   <div>
                    <div block="ProductCard" elem="MultiselectWrapper">{ __('Select your pack quantites') }</div>
                    <div block="ProductCard" elem="MultiselectParentWrapper">
                       { Object.values(option.attribute_options).map((attribute_option) => (
                           <div>
                               { this.renderQuantityChangerMultiSelect({ attribute_code: option.attribute_code, ...attribute_option }) }
                           </div>
                       )) }
                    </div>
                   </div>
               )) }
            </div>
        );
    }

    renderStockAvailableMultiselect() {
        const {
            product: { configurable_options = {} } = {}
        } = this.props;

        return (
            <div
              block="ProductCard"
              elem="Multiselect"
            >
               { Object.values(configurable_options)?.map((option) => (
                   <div>
                        <div block="ProductCard" elem="StockStatusHeading">{ __('Stock available:') }</div>
                        <div block="ProductCard" elem="StockLabels">
                       { Object.values(option.attribute_options)?.map((attribute_option) => (
                           <>
                               { this.renderStockAvailableStatus({ attribute_code: option.attribute_code, ...attribute_option }) }
                           </>
                       )) }
                        </div>
                   </div>
               )) }
            </div>
        );
    }

    renderStockAvailableStatus(item) {
        const { product: { type_id, variants = [] } } = this.props;

        const { attribute_code, value, brand_label } = item;
        const variant = variants?.find((data) => data?.attributes[attribute_code]?.attribute_value === value);

        if (!variant) {
            return null;
        }

        if (type_id === PRODUCT_TYPE.grouped && !variant) {
            return null;
        }

        const inStockStatusLabel = variant?.salable_qty === 0;

        return (
            <div block="ProductCard" elem="StockAvailabilityValue">
                <span block="ProductCard" elem="StockAvailabileBrandLabel">{ brand_label }</span>
                <span block="ProductCard" elem={ inStockStatusLabel ? 'DisableAvailableQty' : 'StockAvailabileSalableQty' }>{ variant?.salable_qty }</span>
            </div>
        );
    }

    renderProductActionsButtons() {
        const { inStock, product: { type_id: type } } = this.props;

        return (
            <div block="ProductCard" elem="QuantityChanger">
                <div block="ProductCard" elem="ConfigurableOptions">
                        { type === PRODUCT_TYPE.configurable ? (
                                <div>
                                { this.renderSwatchMultiselect() }
                                <div block="ProductCard" elem="totalPrice">
                                    <div block="ProductCard" elem="totalPriceWrapperList">
                                    <p block="ProductCard" elem="totalPriceText">Total:</p>
                                    { this.calculateTotalPrice() }
                                    </div>
                                    { !inStock ? this.renderNotifyStockButton() : this.renderAddToCart() }
                                </div>
                                </div>
                        )
                            : (
                                <div>
                                <div block="ProductCard" elem="MultiselectWrapper">{ __('Select your pack quantites') }</div>
                                { this.renderQuantityChanger() }
                                <div block="ProductCard" elem="LastBlockSimpleProducts">
                                { this.renderPrice() }
                                { !inStock ? this.renderNotifyStockButton() : this.renderAddToCart() }
                                </div>
                                </div>
                            ) }
                </div>
            </div>
        );
    }

    getBrandContent() {
        const { layout } = this.props;

        if (this.renderBrandLogo()) {
            return (
                <div block="ProductCard" elem="BrandLogo" mods={ { layout } }>
                    { this.renderBrandLogo() }
                </div>
            );
        }

        return null;
    }

    renderCardContent() {
        const {
            inStock,
            renderContent,
            product: {
                type_id
            }
        } = this.props;

        if (renderContent) {
            return renderContent(this.contentObject);
        }

        return (
            <div block="ProductCard" elem="LinkInnerWrapper">
                        <div block="ProductCard" elem="FigureReview">
                            <figure block="ProductCard" elem="Figure">
                                { this.renderCardLinkWrapper(this.renderPicture()) }
                            </figure>
                            { this.renderLabels() }
                            { this.getBrandContent() }
                        </div>
                        { this.renderProductActions() }
                        <div block="ProductCard" elem="Content">
                            <div block="ProductCard" elem="ContentGrid">
                                { this.renderBrand() }
                                { this.renderCardLinkWrapper(this.renderName(false)) }
                            </div>
                            <div block="ProductCard" elem="ContentStatus">
                            { this.renderSkuName() }
                            { this.renderStatus() }
                            </div>
                            <div block="ProductCard" elem="ContentAddtoCartWrapper">
                            { isSignedIn()
                                ? (
                                <div block="ProductCard" elem="ProductCardLastRow">
                                { this.renderPrice() }
                                { (type_id === PRODUCT_TYPE.configurable)
                                    ? (!inStock ? this.renderNotifyStockButton() : this.renderPopupAddToCart())
                                    : this.renderAddToCart() }
                                </div>
                                )
                                : (
                                <div block="ProductCard" elem="ProductCardPrice">
                                { this.renderLoginLink() }
                                { this.renderRegisterLink() }
                                </div>
                                ) }
                            </div>
                        </div>
            </div>
        );
    }

    renderLabel = (labelData) => {
        const {
            ruleId,
            productId,
            positionStyle,
            textStyle,
            customCss,
            imageSrc,
            label,
            tooltipLabel,
            fontUrl
        } = labelData;

        return (
          <ProductLabel
            key={ `${productId}_${ruleId}` }
            ruleId={ ruleId }
            productId={ productId }
            positionStyle={ positionStyle }
            textStyle={ textStyle }
            customCss={ customCss }
            imageSrc={ imageSrc }
            label={ label }
            fontUrl={ fontUrl }
            tooltipLabel={ tooltipLabel }
          />
        );
    };

    renderLabels() {
        const { labels } = this.props;
        return labels.map(this.renderLabel);
    }

    renderCardListContent() {
        const { layout, renderContent, product: { sku } } = this.props;

        if (!sku) {
            return <TextPlaceholder length="medium" />;
        }

        if (renderContent) {
            return renderContent(this.contentObject);
        }

        return (
            <div block="ProductCard" elem="ProductWrapperList">
                <div block="ProductCard" elem="LinkBlock-1">
                    <div block="ProductCard" elem="Link">
                        <div
                          block="ProductCard"
                          elem="Content"
                          mods={ { layout } }
                        >
                            <div block="ProductCard" elem="ContentMainBlock">
                                <div block="ProductCard" elem="MainInfo">
                                    { this.renderMainDetails() }
                                    { this.renderLabelsList() }
                                </div>
                                { isSignedIn() ? this.renderStockAvailableMultiselect() : null }
                            </div>
                        </div>

                    </div>
                       <div block="ProductCard" elem="ProductsActionIcons">
                        { isSignedIn() ? null : this.renderProductActions() }
                       </div>
                </div>
                <div block="ProductCard" elem="LinkBlock-2">
                    <div block="ProductCard" elem="ActionWrapper">
                        { isSignedIn()
                            ? (
                            <div block="ProductCard" elem="StockInfo">
                            { this.renderProductActionsButtons() }
                            </div>
                            )
                            : (
                            <div block="ProductCard" elem="ProductRegisterWrapper">
                            <span block="ProductCard" elem="ProductLoginText">{ __('Please login / register to see wholesale prices') }</span>
                            <div block="ProductCard" elem="ProductRegister">
                            { this.renderRegisterLink() }
                            { this.renderLoginLink() }
                            </div>
                            </div>
                            ) }
                    </div>
                </div>
            </div>
        );
    }

    renderLabelList = (labelData) => {
        const {
            ruleId,
            productId,
            positionStyle,
            textStyle,
            customCss,
            imageSrc,
            label,
            tooltipLabel,
            fontUrl
        } = labelData;

        return (
          <ProductLabel
            key={ `${productId}_${ruleId}` }
            ruleId={ ruleId }
            productId={ productId }
            positionStyle={ positionStyle }
            textStyle={ textStyle }
            customCss={ customCss }
            imageSrc={ imageSrc }
            label={ label }
            fontUrl={ fontUrl }
            tooltipLabel={ tooltipLabel }
          />
        );
    };

    renderLabelsList() {
        const { labels } = this.props;
        return labels.map(this.renderLabel);
    }

    renderViewProductOnHover() {
        const {
            product: { type_id, url_key, url }, layout
        } = this.props;

        if (type_id === PRODUCT_TYPE.bundle) {
            return (
                        <Link
                          to={ url_key || url }
                          block="Button ViewProduct"
                          mods={ { layout } }
                        >
                            <span>{ __('View Product') }</span>
                        </Link>
            );
        }
    }

    render() {
        const {
            children, mix, isLoading, layout
        } = this.props;

        if (layout === LIST_LAYOUT) {
            return (
                <li block="ProductCard" mods={ { layout } } mix={ mix }>
                    <Loader isLoading={ isLoading } />
                    { this.renderCardListContent() }
                </li>
            );
        }

        return (
            <li block="ProductCard" mods={ { layout } } mix={ mix }>
                <Loader isLoading={ isLoading } />
                { this.renderCardContent() }
                <div block="ProductCard" elem="AdditionalContent">
                    { children }
                </div>
            </li>
        );
    }
}

export default ProductCardComponent;
