/* eslint-disable max-len */
import { PureComponent } from 'react';

/** @namespace Tigerone/Component/CloseIcon/Component */
export class CloseIconComponent extends PureComponent {
    render() {
        // eslint-disable-next-line react/prop-types
        const { isCart = '' } = this.props;

        if (isCart) {
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M10 11.1667L5.91671 15.25C5.76393 15.4028 5.56949 15.4792 5.33337 15.4792C5.09726 15.4792 4.90282 15.4028 4.75004 15.25C4.59726 15.0972 4.52087 14.9028 4.52087 14.6667C4.52087 14.4306 4.59726 14.2361 4.75004 14.0833L8.83337 10L4.75004 5.91668C4.59726 5.7639 4.52087 5.56945 4.52087 5.33334C4.52087 5.09723 4.59726 4.90279 4.75004 4.75001C4.90282 4.59723 5.09726 4.52084 5.33337 4.52084C5.56949 4.52084 5.76393 4.59723 5.91671 4.75001L10 8.83334L14.0834 4.75001C14.2362 4.59723 14.4306 4.52084 14.6667 4.52084C14.9028 4.52084 15.0973 4.59723 15.25 4.75001C15.4028 4.90279 15.4792 5.09723 15.4792 5.33334C15.4792 5.56945 15.4028 5.7639 15.25 5.91668L11.1667 10L15.25 14.0833C15.4028 14.2361 15.4792 14.4306 15.4792 14.6667C15.4792 14.9028 15.4028 15.0972 15.25 15.25C15.0973 15.4028 14.9028 15.4792 14.6667 15.4792C14.4306 15.4792 14.2362 15.4028 14.0834 15.25L10 11.1667Z" fill="#3A3A36" />
                </svg>
            );
        }

        // eslint-disable-next-line react/prop-types, no-magic-numbers
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
            <path d="M7.00005 8.90005L2.10005 13.8C1.91672 13.9834 1.68338 14.075 1.40005 14.075C1.11672 14.075 0.883382 13.9834 0.700048 13.8C0.516715 13.6167 0.425049 13.3834 0.425049 13.1C0.425049 12.8167 0.516715 12.5834 0.700048 12.4L5.60005 7.50005L0.700048 2.60005C0.516715 2.41672 0.425049 2.18338 0.425049 1.90005C0.425049 1.61672 0.516715 1.38338 0.700048 1.20005C0.883382 1.01672 1.11672 0.925049 1.40005 0.925049C1.68338 0.925049 1.91672 1.01672 2.10005 1.20005L7.00005 6.10005L11.9 1.20005C12.0834 1.01672 12.3167 0.925049 12.6 0.925049C12.8834 0.925049 13.1167 1.01672 13.3 1.20005C13.4834 1.38338 13.575 1.61672 13.575 1.90005C13.575 2.18338 13.4834 2.41672 13.3 2.60005L8.40005 7.50005L13.3 12.4C13.4834 12.5834 13.575 12.8167 13.575 13.1C13.575 13.3834 13.4834 13.6167 13.3 13.8C13.1167 13.9834 12.8834 14.075 12.6 14.075C12.3167 14.075 12.0834 13.9834 11.9 13.8L7.00005 8.90005Z" fill="#3A3A36" />
            </svg>
        );
    }
}
export default CloseIconComponent;
