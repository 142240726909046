/* eslint-disable max-lines */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable max-len */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
import CheckoutAddressForm from 'Component/CheckoutAddressForm';
import { CHECKOUT_CHANGE_ADDRESS_POPUP_ID } from 'Component/CheckoutAddressPopup/CheckoutAddressPopup.config';
import CheckoutAddressTable from 'Component/CheckoutAddressTable';
import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';
// import { ADDRESS_POPUP_ID } from 'Component/MyAccountAddressPopup/MyAccountAddressPopup.config';
import Popup from 'Component/Popup/Popup.container';
import {
    STORE_IN_PICK_UP_METHOD_CODE
} from 'Component/StoreInPickUp/StoreInPickUp.config';
import StoreSwitcherV2 from 'Component/StoreSwitcherV2';
import {
    BILLING_STEP, SHIPPING_ADDRESS, SHIPPING_STEP
} from 'Route/Checkout/Checkout.config';
import {
    CheckoutAddressBook as SourceCheckoutAddressBook
} from 'SourceComponent/CheckoutAddressBook/CheckoutAddressBook.component';
import { getDefaultAddressLabel } from 'Util/Address';
import { isSignedIn } from 'Util/Auth';

import './CheckoutAddressBook.style.scss';

/** @namespace Tigerone/Component/CheckoutAddressBook/Component */
export class CheckoutAddressBookComponent extends SourceCheckoutAddressBook {
    ToggleAddressAdd = () => {
        this.setState({
            isCustomAddressExpanded: !this.state.isCustomAddressExpanded
        });
    };

    renderHeading() {
        const { handleAddressEdit, step, isBilling } = this.props;

        if (isBilling) {
            return null;
        }

        return (
            <div block="Checkout-Default" className={ step === SHIPPING_ADDRESS ? 'active' : '' }>
                <h2 block="Checkout" elem="Heading">
                   { __(' 1. Delivery & billing details') }
                </h2>
                { step !== SHIPPING_ADDRESS ? <button type="button" onClick={ handleAddressEdit }>{ __('Edit') }</button> : null }
            </div>
        );
    }

    renderErrorMsg() {
        const { showError, handleAddressChange } = this.props;

        if (!showError) {
            return null;
        }

        return (
            <>
            <div block="CheckoutShipping" elem="ErrorMsg">
                <span>
                    { __('Please choose your delivery destination in order to Place order or') }
                    <span
                      className="ChangeCountryLink"
                      onClick={ handleAddressChange }
                    >
                       { __(' Change the Shipping Address') }
                    </span>
                </span>
            </div>
             <StoreSwitcherV2 key={ 1 } isCheckout />
            </>
        );
    }

    renderSameAsShippingCheckbox() {
        const {
            isSameAsShipping,
            onSameAsShippingChange,
            is_virtual,
            selectedShippingMethod
        } = this.props;

        if (is_virtual) {
            return null;
        }

        return (
            <Field
              type={ FIELD_TYPE.checkbox }
              attr={ {
                  id: 'sameAsShippingAddress',
                  name: 'sameAsShippingAddress',
                  value: 'sameAsShippingAddress',
                  checked: isSameAsShipping && selectedShippingMethod !== STORE_IN_PICK_UP_METHOD_CODE
              } }
              events={ {
                  onChange: onSameAsShippingChange
              } }
              mix={ { block: 'CheckoutBilling', elem: 'Checkbox' } }
              label="Billing & Shipping are the same address"
              onChange={ onSameAsShippingChange }
            />
        );
    }

    renderSignedInContent() {
        const {
            step, showError, showAddress, isPopup
        } = this.props;

        if (!isSignedIn()) {
            return null;
        }

        if (step === SHIPPING_ADDRESS) {
            return (
                <>
                  { this.renderOptionalCustomAddress() }
                    <div block="CheckoutAddressBook" elem="Wrapper" className="OutsidePopup">
                        { this.renderAddressList() }
                    </div>
                  { (showAddress || isPopup) && (
                    <Popup
                      id={ CHECKOUT_CHANGE_ADDRESS_POPUP_ID }
                      clickOutside={ false }
                      mix={ { block: 'ChangeAddressPopup' } }
                    >
                    <div block="CheckoutAddressBook" elem="Wrapper">
                        <div block="CheckoutAddressBook" elem="AddNewAddressButton">
                            { this.renderAddNewAddressBtn() }
                        </div>
                        { this.renderAddressList() }
                    </div>
                    </Popup>
                  ) }
                  { this.renderErrorMsg() }
                  { !showError ? '' : null }
                </>
            );
        }

        // if (isBilling) {
        //     return (
        //         <>
        //         { this.renderOptionalCustomAddress() }
        //         <div block="CheckoutAddressBook" elem="Wrapper">
        //             { this.renderAddressList() }
        //         </div>
        //         </>
        //     );
        // }

        return null;
    }

    renderAddress(address, index) {
        const {
            onAddressSelect, selectedAddressId, isPopup,
            customer: { default_shipping } = {}
        } = this.props;
        const addressNumber = index + 1;
        const { id } = address;
        const postfix = getDefaultAddressLabel(address);

        const { showAddress, handleAddressChange } = this.props;

        if (selectedAddressId !== id && !showAddress) {
            return null;
        }

        return (
            <CheckoutAddressTable
              onClick={ onAddressSelect }
              isSelected={ selectedAddressId === id }
              title={ __('Address #%s%s', addressNumber, postfix) }
              address={ address }
              key={ id }
              isPrimary={ Number(default_shipping) === id }
              showAddress={ showAddress }
              handleAddressChange={ handleAddressChange }
              isPopup={ isPopup }
            />
        );
    }

    renderCustomAddress() {
        const {
            isBilling, onShippingEstimationFieldsChange, isSubmitted, prepareAddressAndSetToQuote
        } = this.props;
        const formPortalId = isBilling ? BILLING_STEP : SHIPPING_STEP;

        return (
            <CheckoutAddressForm
              prepareAddressAndSetToQuote={ prepareAddressAndSetToQuote }
              onShippingEstimationFieldsChange={ onShippingEstimationFieldsChange }
              address={ {} }
              id={ formPortalId }
              isSubmitted={ isSubmitted }
              onAddressUpdate={ this.ToggleAddressAdd }
            />
        );
    }

    renderAddNewAddressBtn() {
        const { isCustomAddressExpanded } = this.state;
        const { showCreateNewPopup, customer: { addresses = [] } = {} } = this.props;

        if (!addresses.length) {
            return null;
        }

        return (
            <button
              block="CheckoutAddressBook"
              elem="Button"
              mods={ { isCustomAddressExpanded } }
              type="button"
              onClick={ showCreateNewPopup }
            >
             { __(' Add New Address') }
            </button>
        );
    }

    renderOptionalCustomAddress() {
        const { isCustomAddressExpanded } = this.state;
        const { isBilling } = this.props;

        return (
            <div
              block="CheckoutAddressBook"
              elem="CustomAddressWrapper"
            >
                <div block="TopContent">
                    { isBilling ? <h4>{ __('Billing address') }</h4> : <h4>{ __('Delivery address') }</h4> }
                    { this.renderAddNewAddressBtn() }
                </div>
                { isCustomAddressExpanded && this.renderCustomAddress() }
            </div>
        );
    }
}

export default CheckoutAddressBookComponent;
