/* eslint-disable max-len */
import { PureComponent } from 'react';

/** @namespace Tigerone/Component/DeleteIcon/Component */
export class DeleteIconComponent extends PureComponent {
    render() {
        // eslint-disable-next-line react/prop-types, no-magic-numbers
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M5.83325 17.5C5.37492 17.5 4.9827 17.3369 4.65659 17.0108C4.32992 16.6842 4.16659 16.2917 4.16659 15.8333V5C3.93047 5 3.73242 4.92028 3.57242 4.76083C3.41297 4.60083 3.33325 4.40278 3.33325 4.16667C3.33325 3.93056 3.41297 3.7325 3.57242 3.5725C3.73242 3.41306 3.93047 3.33333 4.16659 3.33333H7.49992C7.49992 3.09722 7.57992 2.89917 7.73992 2.73917C7.89936 2.57972 8.09714 2.5 8.33325 2.5H11.6666C11.9027 2.5 12.1008 2.57972 12.2608 2.73917C12.4202 2.89917 12.4999 3.09722 12.4999 3.33333H15.8333C16.0694 3.33333 16.2671 3.41306 16.4266 3.5725C16.5866 3.7325 16.6666 3.93056 16.6666 4.16667C16.6666 4.40278 16.5866 4.60083 16.4266 4.76083C16.2671 4.92028 16.0694 5 15.8333 5V15.8333C15.8333 16.2917 15.6702 16.6842 15.3441 17.0108C15.0174 17.3369 14.6249 17.5 14.1666 17.5H5.83325ZM5.83325 5V15.8333H14.1666V5H5.83325ZM7.49992 13.3333C7.49992 13.5694 7.57992 13.7672 7.73992 13.9267C7.89936 14.0867 8.09714 14.1667 8.33325 14.1667C8.56936 14.1667 8.76742 14.0867 8.92742 13.9267C9.08686 13.7672 9.16658 13.5694 9.16658 13.3333V7.5C9.16658 7.26389 9.08686 7.06583 8.92742 6.90583C8.76742 6.74639 8.56936 6.66667 8.33325 6.66667C8.09714 6.66667 7.89936 6.74639 7.73992 6.90583C7.57992 7.06583 7.49992 7.26389 7.49992 7.5V13.3333ZM10.8333 13.3333C10.8333 13.5694 10.9133 13.7672 11.0733 13.9267C11.2327 14.0867 11.4305 14.1667 11.6666 14.1667C11.9027 14.1667 12.1008 14.0867 12.2608 13.9267C12.4202 13.7672 12.4999 13.5694 12.4999 13.3333V7.5C12.4999 7.26389 12.4202 7.06583 12.2608 6.90583C12.1008 6.74639 11.9027 6.66667 11.6666 6.66667C11.4305 6.66667 11.2327 6.74639 11.0733 6.90583C10.9133 7.06583 10.8333 7.26389 10.8333 7.5V13.3333Z" fill="black" />
            </svg>
        );
    }
}
export default DeleteIconComponent;
