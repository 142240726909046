/* eslint-disable max-len */
import { PureComponent } from 'react';

/** @namespace Tigerone/Component/InfoIcon/Component */
export class InfoIconComponent extends PureComponent {
    render() {
        // eslint-disable-next-line react/prop-types, no-magic-numbers
        const { width = 24, height = 24 } = this.props;
        return (
            <svg xmlns="http://www.w3.org/2000/svg" height={ height } viewBox="0 0 48 48" width={ width }>
                <path d="M0 0h48v48h-48z" fill="none" />
                <path d="M22 34h4v-12h-4v12zm2-30c-11.05 0-20 8.95-20 20s8.95 20 20 20 20-8.95 20-20-8.95-20-20-20zm0 36c-8.82 0-16-7.18-16-16s7.18-16 16-16 16 7.18 16 16-7.18 16-16 16zm-2-22h4v-4h-4v4z" />
            </svg>
        );
    }
}
export default InfoIconComponent;
