/* eslint-disable max-len */
import { PureComponent } from 'react';

/** @namespace Tigerone/Component/DropDown/Component */
export class DropDownComponent extends PureComponent {
    render() {
        // eslint-disable-next-line react/prop-types
        const { direction } = this.props;
        if (direction === 'bottom') {
            return (
                <svg width="24" height="25" viewBox="0 0 8 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.2998 14.5L8.69977 11.9C8.3831 11.5833 8.31243 11.221 8.48777 10.813C8.66243 10.4043 8.97477 10.2 9.42477 10.2H14.5748C15.0248 10.2 15.3371 10.4043 15.5118 10.813C15.6871 11.221 15.6164 11.5833 15.2998 11.9L12.6998 14.5C12.5998 14.6 12.4914 14.675 12.3748 14.725C12.2581 14.775 12.1331 14.8 11.9998 14.8C11.8664 14.8 11.7414 14.775 11.6248 14.725C11.5081 14.675 11.3998 14.6 11.2998 14.5Z" fill="#232320" />
                </svg>
            );
        }

        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 8 25" fill="none">
            <path d="M11.2998 10.5L8.69977 13.1C8.3831 13.4167 8.31243 13.779 8.48777 14.187C8.66243 14.5957 8.97477 14.8 9.42477 14.8H14.5748C15.0248 14.8 15.3371 14.5957 15.5118 14.187C15.6871 13.779 15.6164 13.4167 15.2998 13.1L12.6998 10.5C12.5998 10.4 12.4914 10.325 12.3748 10.275C12.2581 10.225 12.1331 10.2 11.9998 10.2C11.8664 10.2 11.7414 10.225 11.6248 10.275C11.5081 10.325 11.3998 10.4 11.2998 10.5Z" fill="#232320" />
            </svg>
        );
    }
}

export default DropDownComponent;
