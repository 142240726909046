/* eslint-disable max-lines */
/* eslint-disable consistent-return */
import { connect } from 'react-redux';

import NotifyStockStatusQuery from 'Query/NotifyStockStatus.query';
import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    ProductContainer as SourceProductContainer
} from 'SourceComponent/Product/Product.container';
import { showNotification } from 'Store/Notification/Notification.action';
import fromCache from 'Util/Cache/Cache';
import history from 'Util/History';
import { ADD_TO_CART, getNewParameters, getVariantIndex } from 'Util/Product';
import {
    getMaxQuantity,
    getMinQuantity,
    getName,
    getPrice,
    getProductInStock
} from 'Util/Product/Extract';
import { magentoProductTransform, transformParameters } from 'Util/Product/Transform';
import { fetchMutation, getErrorMessage } from 'Util/Request';
import { appendWithStoreCode } from 'Util/Url';
import { validateGroup } from 'Util/Validator';

export const ProductDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Product/Product.dispatcher'
);

/** @namespace Tigerone/Component/Product/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    saveSelectedConfigDetails: (options) => ProductDispatcher.then(
        ({ default: dispatcher }) => dispatcher.saveSelectedConfigDetails(options, dispatch)
    ),
    showNotification: (type, message) => dispatch(showNotification(type, message))
    // TODO extend mapDispatchToProps
});

/** @namespace Tigerone/Component/Product/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    category: state.CategoryReducer?.category
    // TODO extend mapStateToProps
});

/** @namespace Tigerone/Component/Product/Container */
export class ProductContainer extends SourceProductContainer {
    state = {
        // Used for customizable & bundle options
        enteredOptions: this.setDefaultProductOptions('defaultEnteredOptions', 'enteredOptions'),
        selectedOptions: this.setDefaultProductOptions('defaultSelectedOptions', 'selectedOptions'),
        addToCartTriggeredWithError: false,
        // Used for downloadable
        downloadableLinks: [],

        quantity: 1,

        // Used to add to the base price a selected option prices
        adjustedPrice: {},

        // Used for configurable product - it can be ether parent or variant
        selectedProduct: null,
        multiSelectedProducts: [],
        isMultiSelected: false,
        // eslint-disable-next-line react/destructuring-assignment
        parameters: this.props.parameters,
        isShowPopup: false
    };

    getMagentoProduct(configProduct) {
        const {
            quantity,
            enteredOptions,
            selectedOptions,
            downloadableLinks,
            parameters
        } = this.state;

        const { product, product: { attributes } } = this.props;
        const activeProduct = {
            productAttribute: configProduct ? configProduct?.product?.attributes : attributes,
            product: configProduct ? configProduct?.product : product,
            productQuantity: configProduct ? configProduct?.quantity : quantity,
            parameters: configProduct ? configProduct?.parameters : parameters
        };

        const configurableOptions = transformParameters(activeProduct.parameters, activeProduct.productAttribute);

        return magentoProductTransform(
            ADD_TO_CART,
            activeProduct.product,
            activeProduct.productQuantity,
            enteredOptions,
            [...selectedOptions, ...downloadableLinks, ...configurableOptions],
        );
    }

    containerFunctions = {
        addToCart: this.addToCart.bind(this),

        // Used to update entered and selected state values
        updateSelectedValues: this.updateSelectedValues.bind(this),
        setDownloadableLinks: this.setStateOptions.bind(this, 'downloadableLinks'),
        setQuantity: this.setQuantity.bind(this),
        setAdjustedPrice: this.setAdjustedPrice.bind(this),
        setMultiSelect: this.renderMultiSelectValues.bind(this),
        getActiveProduct: this.getActiveProduct.bind(this),
        setActiveProduct: this.updateConfigurableVariant.bind(this),
        getMagentoProduct: this.getMagentoProduct.bind(this),
        setValidator: this.setValidator.bind(this),
        scrollOptionsIntoView: this.scrollOptionsIntoView.bind(this),
        updateAddToCartTriggeredWithError: this.updateAddToCartTriggeredWithError.bind(this),
        onClickShowPopup: this.onClickShowPopup.bind(this),
        handlePopup: this.handlePopup.bind(this),
        notifyClick: this.notifyClick.bind(this)
    };

    // TODO implement logic
    containerProps() {
        const {
            quantity,
            parameters,
            adjustedPrice,
            unselectedOptions,
            addToCartTriggeredWithError,
            isShowPopup,
            multiSelectedProducts
        } = this.state;
        const {
            product,
            product: { options = [] } = {},
            configFormRef,
            device,
            isWishlistEnabled,
            category = {},
            saveSelectedConfigDetails
        } = this.props;

        const activeProduct = this.getActiveProduct();
        const magentoProduct = this.getMagentoProduct();
        const {
            price_range: priceRange = {},
            dynamic_price: dynamicPrice = false,
            type_id: type
        } = activeProduct || {};

        const output = {
            inStock: fromCache(getProductInStock, [activeProduct, product]),
            maxQuantity: getMaxQuantity(activeProduct),
            minQuantity: getMinQuantity(activeProduct),
            productName: getName(product),
            productPrice: fromCache(getPrice, [priceRange, dynamicPrice, adjustedPrice, type, options])
        };

        return {
            isWishlistEnabled,
            unselectedOptions,
            isShowPopup,
            quantity,
            product,
            configFormRef,
            parameters,
            device,
            magentoProduct,
            addToCartTriggeredWithError,
            category,
            saveSelectedConfigDetails,
            multiSelectedProducts,
            ...output
        };
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            enteredOptions,
            selectedOptions,
            downloadableLinks
        } = this.state;

        const {
            enteredOptions: prevEnteredOptions,
            selectedOptions: prevSelectedOptions,
            downloadableLinks: prevDownloadableLinks
        } = prevState;

        if (
            enteredOptions !== prevEnteredOptions
            || selectedOptions !== prevSelectedOptions
            || downloadableLinks !== prevDownloadableLinks
        ) {
            this.updateAdjustedPrice();
        }

        const { product } = this.props;
        const { product: prevProduct } = prevProps;

        if (product !== prevProduct) {
            const quantity = ProductContainer.getDefaultQuantity(this.props, this.state);

            if (quantity) {
                this.setQuantity(quantity);
            }

            this.updateSelectedValues();
        }
    }

    async addToCart(isBuyNow = false) {
        this.updateSelectedValues();
        const { showError } = this.props;
        const { multiSelectedProducts, isMultiSelected } = this.state;

        if (this.hasError()) {
            return;
        }

        const { addProductToCart, cartId } = this.props;
        const products = multiSelectedProducts.length && isMultiSelected
            ? multiSelectedProducts.map((item) => this.getMagentoProduct(
                item
            )[0])
            : this.getMagentoProduct();

        await addProductToCart({ products, cartId })
            .catch(
                /** @namespace Tigerone/Component/Product/Container/ProductContainer/addToCart/addProductToCart/catch */
                (error) => {
                    if (error) {
                        showError(error);
                    }
                }
            );

        if (isBuyNow) {
            history.push({ pathname: appendWithStoreCode('/checkout') });
        }
    }

    notifyClick(data) {
        const {
            customerEmailId, website_id, showNotification
        } = this.props;

        const { id } = this.getActiveProduct();
        const { id: parentId } = data;

        const options = {
            id,
            parentId,
            website_id,
            email: customerEmailId
        };

        const mutation = NotifyStockStatusQuery.getNotifyStockStatus(options);
        return fetchMutation(mutation).then(
            /** @namespace Tigerone/Component/Product/Container/ProductContainer/notifyClick/fetchMutation/then */
            (data) => {
                if (!data.error) {
                    const { subscribeStockNotice } = data;
                    if (subscribeStockNotice[0]) {
                        const { message } = subscribeStockNotice[0];
                        if (message === 'true') {
                            showNotification('success', 'Stock status notify Successfully added');
                        } else {
                            showNotification('info', message);
                        }
                    } else {
                        showNotification('success', 'Stock status notify Successfully added');
                    }
                }
            },

            /** @namespace Tigerone/Component/Product/Container/ProductContainer/notifyClick/fetchMutation/then/catch */
            (error) => {
                showNotification('error', getErrorMessage(error));
            }
        );
    }

    onClickShowPopup() {
        this.setState({
            isShowPopup: true
        });
    }

    handlePopup() {
        this.setState({
            isShowPopup: false
        });
    }

    validateConfigurableProduct() {
        const {
            parameters,
            isMultiSelected,
            multiSelectedProducts
        } = this.state;

        if (!isMultiSelected) {
            const { product: { configurable_options } } = this.props;
            const unselectedOptions = Object.keys(configurable_options).reduce((accumulator, value) => {
                if (!parameters[value]) {
                    accumulator.push(value);
                }

                return accumulator;
            }, []);

            this.setState({ unselectedOptions });

            return unselectedOptions.length > 0;
        }

        return !multiSelectedProducts.length;
    }

    hasError() {
        const { errorMessages, errorFields, values } = validateGroup(this.validator);
        const { showNotification } = this.props;

        if (
            errorFields
            || errorMessages
            || this.validateConfigurableProduct()
            || this.filterAddToCartFileErrors(values)
        ) {
            this.scrollOptionsIntoView();
            this.setState({ addToCartTriggeredWithError: true });
            showNotification('info', __('Incorrect or missing options!'));

            return true;
        }

        return false;
    }

    renderMultiSelectValues(key, value, qty, price, parentSku) {
        const { newProduct, parameters } = this.updateConfigurableVariant(key, value, false, true);
        this.setState((prevState) => {
            const { multiSelectedProducts } = prevState;
            const existingProductIndex = multiSelectedProducts.findIndex(
                (item) => item.attribute_value === value && item.attribute_code === key
            );

            if (existingProductIndex !== -1) {
            // Update quantity if the product exists
                const updateSelectedProducts = Array.from(multiSelectedProducts);
                if (qty === 0) {
                // If qty is zero, remove the product
                    updateSelectedProducts.splice(existingProductIndex, 1);
                } else {
                    updateSelectedProducts[existingProductIndex] = {
                        ...updateSelectedProducts[existingProductIndex],
                        quantity: qty
                    };
                }

                return { multiSelectedProducts: updateSelectedProducts, isMultiSelected: true };
            }

            return {
                multiSelectedProducts: [
                    ...multiSelectedProducts,
                    {
                        attribute_value: value,
                        quantity: qty,
                        attribute_code: key,
                        product: { ...newProduct, sku: parentSku },
                        parameters,
                        price
                    }
                ],
                isMultiSelected: true
            };
        });
    }

    getActiveProduct() {
        const { selectedProduct } = this.state;
        const { product } = this.props;
        return selectedProduct || product;
    }

    updateConfigurableVariant(key, value, checkEmptyValue = false, isMultiSelect = false) {
        const { parameters: prevParameters, quantity } = this.state;
        const { saveSelectedConfigDetails } = this.props;

        const newParameters = getNewParameters(prevParameters, key, value);

        const { [key]: oldValue, ...currentParameters } = newParameters;
        const parameters = oldValue === '' && checkEmptyValue ? currentParameters : newParameters;

        this.setState({ parameters });

        const { product: { variants, configurable_options } } = this.props;

        const newIndex = Object.keys(parameters).length === Object.keys(configurable_options).length
            ? getVariantIndex(variants, parameters)
            // Not all parameters are selected yet, therefore variantIndex must be invalid
            : -1;

        const newProduct = newIndex === -1 ? null : variants[newIndex];

        if (isMultiSelect) {
            return { newProduct, parameters };
        }
        saveSelectedConfigDetails({ parameters, quantity });
        this.setState({ selectedProduct: newProduct, parameters, isMultiSelected: false });

        return newProduct;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductContainer);
