import { CmsBlock as SourceCmsBlock } from 'SourceComponent/CmsBlock/CmsBlock.component';
import Html from 'SourceComponent/Html';

/** @namespace Tigerone/Component/CmsBlock/Component */
export class CmsBlockComponent extends SourceCmsBlock {
    render() {
        const {
            cmsBlock: {
                identifier,
                content,
                disabled
            },
            blockType
        } = this.props;

        if (disabled) {
            return null;
        }

        if (identifier === undefined) {
            return this.renderPlaceholder();
        }

        return (
            <div block="CmsBlock" elem="Wrapper" mods={ { type: blockType } }>
                <Html content={ content } />
            </div>
        );
    }
}
export default CmsBlockComponent;
