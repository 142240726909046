/* eslint-disable max-len */
import { PureComponent } from 'react';

/** @namespace Tigerone/Component/BitCoinLogo/Component */
export class BitCoinLogoComponent extends PureComponent {
    render() {
        return (
            <svg width="84" height="16" viewBox="0 0 84 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.2785 9.93207C17 10.9513 16.5042 11.9105 15.8193 12.7549C15.1344 13.5992 14.2738 14.3123 13.2868 14.8532C12.2998 15.3942 11.2056 15.7525 10.0667 15.9076C8.92789 16.0628 7.76669 16.0118 6.64947 15.7575C4.39374 15.2441 2.45393 13.9343 1.2567 12.1163C0.0594793 10.2983 -0.297114 8.12097 0.265339 6.06316C0.543629 5.04396 1.03927 4.08476 1.72396 3.24032C2.40866 2.39588 3.269 1.68274 4.25584 1.14163C5.24268 0.600525 6.33671 0.242043 7.47544 0.086658C8.61417 -0.0687273 9.7753 -0.0179718 10.8925 0.236027C12.0098 0.490062 13.0613 0.942391 13.9869 1.56718C14.9126 2.19196 15.6942 2.97697 16.2872 3.87736C16.8802 4.77775 17.273 5.77589 17.4431 6.81477C17.6132 7.85365 17.5573 8.91291 17.2785 9.93207Z" fill="#F39322" />
            <path d="M12.6394 6.86242C12.8143 5.79847 11.9251 5.22492 10.7104 4.84312L11.1048 3.40246L10.1412 3.18356L9.75804 4.58689C9.50505 4.5292 9.24462 4.4749 8.98792 4.4172L9.37298 3.00369L8.41313 2.78479L8.01877 4.22545C7.80857 4.18303 7.60209 4.13891 7.40305 4.09479L6.07674 3.79274L5.82004 4.72943C5.82004 4.72943 6.53435 4.87876 6.5176 4.88894C6.65061 4.90345 6.77213 4.96481 6.85624 5.05991C6.94034 5.15502 6.98036 5.27635 6.96776 5.398L6.5176 7.04738C6.55187 7.05456 6.58546 7.0642 6.61805 7.07623L6.5176 7.04738L5.88886 9.35007C5.87492 9.39013 5.85244 9.42728 5.82271 9.45939C5.79297 9.4915 5.75658 9.51794 5.71561 9.53717C5.67464 9.55641 5.62991 9.56807 5.58399 9.57149C5.53806 9.57491 5.49185 9.57001 5.448 9.55709C5.448 9.57066 4.74857 9.39758 4.74857 9.39758L4.27051 10.4038L5.52241 10.6889C5.7568 10.7415 5.98374 10.7975 6.20882 10.8501L5.81074 12.3078L6.77245 12.5266L7.1668 11.0843C7.42909 11.1488 7.68393 11.2082 7.9332 11.2659L7.5407 12.7014L8.50242 12.9203L8.90049 11.4644C10.5412 11.7478 11.7745 11.6341 12.2935 10.2766C12.712 9.18717 12.273 8.55762 11.4099 8.14867C12.0386 8.01801 12.513 7.6413 12.6394 6.86242ZM10.4407 9.67417C10.1431 10.7636 8.13037 10.1832 7.47931 10.0271L8.00761 8.09437C8.65867 8.24369 10.7514 8.53725 10.4407 9.67417ZM10.7383 6.84715C10.4668 7.83814 8.7926 7.33416 8.24943 7.21029L8.7275 5.4574C9.27067 5.58127 11.0211 5.81205 10.7383 6.84715Z" fill="white" />
            <path d="M25.7038 4.84137C26.3379 4.82626 26.9674 4.94228 27.5454 5.18075C28.0465 5.39945 28.4877 5.71777 28.8363 6.11234C29.1807 6.52282 29.4335 6.99095 29.5804 7.49022C29.7454 8.04316 29.8267 8.61407 29.8222 9.18711C29.8313 10.0929 29.6416 10.9913 29.2642 11.8292C28.907 12.637 28.3843 13.3753 27.7239 14.0046C27.0657 14.6223 26.2815 15.1169 25.4155 15.4605C24.5065 15.8225 23.5239 16.0052 22.5322 15.9967C22.3982 15.9967 22.1601 15.9967 21.8309 15.9866C21.4486 15.9766 21.0673 15.9465 20.6887 15.8966C20.2292 15.8371 19.7735 15.7555 19.3234 15.6523C18.852 15.5494 18.3942 15.4003 17.958 15.2077L21.7974 0.488838L25.2369 0.00183105L23.8622 5.22487C24.1484 5.10742 24.4453 5.01317 24.7495 4.94318C25.0617 4.87298 25.3822 4.83821 25.7038 4.83967V4.84137ZM22.8187 13.5091C23.3304 13.5117 23.8343 13.3949 24.2826 13.1697C24.7442 12.9406 25.1489 12.6269 25.4713 12.2483C25.8103 11.8548 26.0767 11.4134 26.26 10.9417C26.4523 10.4546 26.5499 9.94067 26.5483 9.42298C26.5483 8.77477 26.4292 8.27079 26.193 7.89577C25.9568 7.52076 25.5196 7.34937 24.8909 7.34937C24.6182 7.35821 24.3471 7.39175 24.0817 7.44949C23.7466 7.51981 23.4367 7.66699 23.1814 7.87711L21.7174 13.4243L21.9499 13.4633C22.0136 13.4779 22.0782 13.4887 22.1434 13.4955C22.2324 13.5044 22.3218 13.5083 22.4113 13.5074L22.8187 13.5091Z" fill="#C7C6C6" />
            <path d="M33.3993 15.7167H30.1143L32.8878 5.06874H36.1952L33.3993 15.7167ZM34.9972 3.77061C34.5494 3.77357 34.1129 3.64242 33.7546 3.3973C33.3826 3.14785 33.1966 2.76605 33.1966 2.25189C33.1947 1.97636 33.2581 1.70372 33.3826 1.45266C33.5035 1.20994 33.6732 0.990066 33.883 0.804445C34.0938 0.621412 34.3389 0.47429 34.6066 0.370041C34.8832 0.258683 35.1822 0.200902 35.4846 0.200351C35.9326 0.197883 36.3692 0.32963 36.7272 0.575365C37.0992 0.824808 37.2852 1.20661 37.2852 1.71907C37.2871 1.99514 37.2237 2.26834 37.0992 2.52C36.9783 2.76229 36.8093 2.98206 36.6007 3.16822C36.3907 3.3514 36.1462 3.49855 35.8789 3.60262C35.6011 3.71409 35.3008 3.77132 34.9972 3.77061Z" fill="#C7C6C6" />
            <path d="M39.0703 2.41486L42.5116 1.92786L41.6652 5.06711H45.3502L44.6862 7.53778H41.0216L40.0469 11.2709C39.9619 11.5548 39.9101 11.846 39.8925 12.1398C39.8705 12.3774 39.908 12.6164 40.0022 12.8389C40.0939 13.0389 40.2635 13.2009 40.4784 13.2936C40.7888 13.4192 41.127 13.4773 41.4662 13.4633C41.8134 13.464 42.1599 13.4333 42.5004 13.3717C42.8429 13.3101 43.1799 13.2256 43.5087 13.1189L43.7542 15.4283C43.2827 15.5847 42.8005 15.7128 42.3107 15.8118C41.7053 15.9277 41.0876 15.9818 40.4691 15.973C39.4473 15.973 38.6555 15.8356 38.0937 15.5607C37.5658 15.3154 37.1433 14.9157 36.8939 14.4255C36.6502 13.9055 36.5438 13.3405 36.5832 12.7761C36.6196 12.1137 36.7236 11.4559 36.8939 10.8111L39.0703 2.41486Z" fill="#C7C6C6" />
            <path d="M45.2092 11.4592C45.2024 10.576 45.3674 9.69869 45.6966 8.86807C46.0051 8.08183 46.4804 7.35876 47.0954 6.74016C47.7134 6.12776 48.4648 5.63994 49.3016 5.30799C50.2151 4.94881 51.2014 4.76956 52.196 4.78195C52.7965 4.77418 53.3959 4.83114 53.9818 4.95164C54.476 5.05679 54.9568 5.20923 55.416 5.40641L54.2831 7.7566C53.9874 7.6463 53.6786 7.55128 53.3623 7.46304C52.9829 7.36664 52.5898 7.32204 52.196 7.33068C51.1004 7.33068 50.2335 7.67006 49.5918 8.36239C48.95 9.05473 48.6263 9.97614 48.6263 11.1368C48.6263 11.8258 48.7894 12.3829 49.1156 12.8083C49.4392 13.2342 50.0456 13.4463 50.9125 13.4463C51.3303 13.4466 51.7469 13.4057 52.1551 13.3241C52.5197 13.2533 52.8765 13.1522 53.221 13.0221L53.4665 15.4299C53.0175 15.5876 52.5585 15.7202 52.0918 15.827C51.5017 15.948 50.8979 16.0049 50.293 15.9967C49.4975 16.016 48.7056 15.8927 47.9622 15.6336C47.3656 15.4135 46.8281 15.0783 46.3867 14.6511C45.9699 14.2369 45.6626 13.7412 45.4882 13.2019C45.3003 12.637 45.2063 12.0496 45.2092 11.4592Z" fill="#C7C6C6" />
            <path d="M59.804 16.0002C59.1051 16.0142 58.4101 15.9035 57.7578 15.6744C57.2091 15.4774 56.719 15.1652 56.3273 14.7631C55.9407 14.3595 55.6495 13.8876 55.4716 13.3768C55.2729 12.8046 55.1762 12.2071 55.1851 11.6069C55.1904 10.7802 55.3392 9.95951 55.626 9.17528C55.9032 8.38245 56.3356 7.64197 56.9021 6.98968C57.4673 6.33962 58.1685 5.79857 58.965 5.398C59.8223 4.97886 60.7828 4.76681 61.7553 4.78203C62.4495 4.76994 63.1398 4.87998 63.7885 5.10613C64.3402 5.30335 64.8339 5.61538 65.2301 6.01736C65.6148 6.42255 65.9053 6.89478 66.0839 7.40542C66.2825 7.97766 66.3798 8.5751 66.3722 9.17528C66.3689 10.0006 66.2271 10.8207 65.9518 11.6069C65.6814 12.3963 65.2608 13.1361 64.7092 13.7925C64.1556 14.446 63.4587 14.9869 62.663 15.3808C61.7838 15.807 60.7994 16.0203 59.804 16.0002ZM61.4446 7.33415C60.9813 7.32765 60.5288 7.46264 60.1592 7.71765C59.7826 7.98322 59.4666 8.31355 59.2292 8.68997C58.9738 9.09187 58.7828 9.525 58.6618 9.97621C58.542 10.4096 58.4796 10.8545 58.4758 11.3015C58.4758 11.9802 58.5967 12.5046 58.8311 12.883C59.0655 13.2614 59.497 13.4498 60.1183 13.4498C60.5826 13.4568 61.0361 13.3211 61.4056 13.0646C61.7821 12.7989 62.0981 12.4686 62.3357 12.0922C62.5919 11.6908 62.783 11.2575 62.903 10.806C63.0228 10.3726 63.0852 9.92767 63.089 9.48072C63.089 8.80196 62.97 8.27932 62.7337 7.90091C62.4975 7.52251 62.0659 7.33415 61.4446 7.33415Z" fill="#C7C6C6" />
            <path d="M69.95 15.7167H66.6631L69.4366 5.06875H72.7403L69.95 15.7167ZM71.546 3.77062C71.0987 3.77422 70.6625 3.64299 70.3053 3.39731C69.9333 3.14843 69.7473 2.76663 69.7473 2.2519C69.7454 1.97637 69.8088 1.70373 69.9333 1.45267C70.0542 1.20995 70.2239 0.990076 70.4337 0.804456C70.6437 0.621274 70.8882 0.474121 71.1554 0.370052C71.4308 0.258729 71.7285 0.200939 72.0297 0.200362C72.4784 0.197522 72.9156 0.329288 73.2742 0.575375C73.6462 0.824253 73.8322 1.20549 73.8322 1.71908C73.8332 1.99507 73.7697 2.26809 73.6462 2.52001C73.526 2.76265 73.3569 2.98254 73.1477 3.16823C72.9376 3.35056 72.6939 3.49762 72.4278 3.60263C72.1503 3.71345 71.8508 3.77065 71.5479 3.77062H71.546Z" fill="#C7C6C6" />
            <path d="M75.1152 5.5524C75.3644 5.48622 75.6435 5.40647 75.9448 5.31823C76.2462 5.22999 76.5884 5.14854 76.9679 5.07727C77.3859 4.99663 77.8081 4.93546 78.2328 4.89401C78.7593 4.84369 79.2884 4.81989 79.8177 4.82274C81.5539 4.82274 82.7493 5.28203 83.4041 6.20061C84.0589 7.1192 84.1742 8.37433 83.7501 9.96602L82.2378 15.7168H78.9323L80.3962 10.0865C80.4855 9.73524 80.5562 9.39586 80.6083 9.06836C80.6616 8.78043 80.6572 8.48606 80.5953 8.19955C80.5423 7.95868 80.3972 7.74279 80.186 7.59037C79.8939 7.41569 79.5466 7.33397 79.1983 7.3579C78.7608 7.36002 78.3247 7.40151 77.8961 7.48177L75.7439 15.7202H72.4365L75.1152 5.5524Z" fill="#C7C6C6" />
            </svg>
        );
    }
}

export default BitCoinLogoComponent;
